@tailwind base;
@tailwind components;
@tailwind utilities;

/**
 * Custom base styles
 */

 html {
  font-size: 62.5%;
  font-family: 'Futura PT', sans-serif;
  background-color: #F1F5F9;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: 100%;
}

body {
  font-size: 13px;
  line-height: 1.4;
  overflow-x: hidden;
}

html, body, #root {
  position: relative;
  margin: 0;
  min-height: 100vh;
}

h1, .h1 {
  font-size: 24px;
}

h2, .h2 {
  font-size: 20px;
}

h3, .h3 {
  font-size: 16px;
}

h4, .h4 {
  font-size: 15px;
}

h5, .h5 {
  font-size: 13px;
}

h6, .h6 {
  font-size: 12px;
}

.ps > .ps__rail-y,
.ps > .ps__rail-x {
  z-index: 99;
}

a[role=button] {
  text-decoration: none;
}

[role="tooltip"] {
  z-index: 9999;
}

/* Medium Devices, Desktops Only */
@media only screen and (min-width: 992px) {
  ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background-color: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar:hover {
      width: 8px;
      height: 8px;
      background-color: rgba(0, 0, 0, 0.06);
  }

  ::-webkit-scrollbar-thumb {
      border: 2px solid transparent;
      border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb:active {
      border-radius: 20px;
  }
}

form label {
  z-index: 99;
}

body.no-animate *,
body.no-animate *::before,
body.no-animate *::after {
  transition: none !important;
  animation: none !important;
}

button:focus {
  outline: none;
}

/* Removes webkit's autofill backgorund color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active
{
  transitionDelay: 9999s;
  transitionProperty: background-color, color;
}

:focus {
  outline-color: transparent;
}

/*fullcalendar Fix*/
.fc-scrollgrid-section-liquid {
  height: 1px !important;
}
